<template lang="pug">
.Dates
  .ContainerHeader.pb-5.position-relative
    img(
      v-if="!fromQR"
      src="@/assets/mobileFSFB/icn_backicn_back.png"
      alt="icn_backicn_back"
      @click="goBack"
    ).icn_backicn_back.ml-3.backButton
    div(v-else).icn_backicn_back.ml-3.backButton
    .ContainerUser.ml-4.d-flex.mt-3
      img(src="@/assets/mobileFSFB/user.svg" alt="user")
      div(style="height: 50px;").ml-3
        p.mb-0: small.text-Small Bienvenido
        p.text-Principal {{ fullname }}
      img(src="@/assets/mobileFSFB/bg_dates.png" alt="bg_dates").bg_dates
  #ContainerData.ContainerData.pt-4.px-2
    button#arrowBtm.ArrowDownBold(@click="onScrollBtm")
      ArrowDownBold
    h6.subTitle Tus citas
    .glider.mt-4
      .w-100.card-glider.pb-3(
          v-for="cita in citas"
        )
        .cardCarrousel.mx-auto.d-block.pb-4
          h6.subTitle.mx-auto.text-start.py-2.pl-3: strong Trámite: {{cita.service.detail}}
          p(style="font-family: var(--font-secondary);").doctorName.pl-3 Dr(a). {{cita.staff.name}} {{cita.staff.last_name}} {{cita.staff.mother_last_name}}
          .dateCard.d-flex.align-items-center.p-3.mx-3
            svg(width='24' height='25' viewbox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg')
              path(d='M9.46056 5.62695V7.31721' stroke='#5AC3F0' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
              path(d='M14.8297 5.62695V7.31721' stroke='#5AC3F0' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
              path(d='M7.92462 6.33936H16.3411C16.7418 6.33936 17.1261 6.50672 17.4094 6.80462C17.6927 7.10251 17.8519 7.50655 17.8519 7.92784V16.4499C17.8519 16.8724 17.6923 17.2775 17.4081 17.5763C17.124 17.875 16.7387 18.0428 16.3369 18.0428H7.92041C7.51973 18.0428 7.13546 17.8755 6.85214 17.5776C6.56882 17.2797 6.40965 16.8756 6.40965 16.4543V7.92784C6.40965 7.71887 6.44887 7.51195 6.52506 7.31893C6.60124 7.12592 6.7129 6.95061 6.85363 6.80305C6.99436 6.65549 7.16141 6.53857 7.34519 6.459C7.52897 6.37944 7.72587 6.33878 7.92462 6.33936V6.33936Z' stroke='#5AC3F0' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
              path(d='M6.41446 9.32617H17.8777' stroke='#5AC3F0' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
            p.mb-0 {{citaFecha(cita.date_appoinment)}}
            svg(width='24' height='25' viewbox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg')
              path(d='M10.9219 18.043C14.0735 18.043 16.6283 15.3567 16.6283 12.043C16.6283 8.72926 14.0735 6.04297 10.9219 6.04297C7.77031 6.04297 5.21545 8.72926 5.21545 12.043C5.21545 15.3567 7.77031 18.043 10.9219 18.043Z' stroke='#89D4F4' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
              path(d='M10.9219 12.0431V8.79297' stroke='#89D4F4' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
              path(d='M10.9219 12.043H12.871' stroke='#89D4F4' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
            p.mb-0 {{citaHora(cita.date_appoinment + ' ' + cita.hour_appoinment)}}
          .dateCard.d-flex.align-items-center.p-3.mx-3.mt-3
            p.mb-0 № de confirmación: {{cita.number_form}}
          .px-3
            button(@click="onSelectedThisProcedure(cita)" :class="availableTime(cita) ? '' : 'disableSchedule' ").buttonForm.d-block.w-100.mt-4
              span(v-if="loading && loading === 'loadingSelect'")
                MiniSpinner
              span(v-else) {{ availableTime(cita) ? "Seleccionar" : "Cita fuera de horario" }}
    .pt-0.pb-0
      #dots
    .pb-4
      .px-3.pt-0.pb-5
        button(@click="() => goToView({ view: 'Procedure' })").buttonForm.d-block.w-100.mt-4.buttonOutlined#anotherServices
          span Ver otros servicios
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import ArrowDownBold from "mdi-vue/ArrowDownBold";

export default {
  name: "Dates",

  data() {
    return {
      selectedSchedule: {},
      loading: false,
      isArrowBtnFirstTime: true,
      observer: null
    };
  },

  created() {
    // No dates temporaly
    // this.goToView({ view: 'Procedure' })
  },

  components: {
    MiniSpinner: () => import("./components/MiniSpinner"),
    ArrowDownBold
  },

  mounted() {
    new window.Glider(document.querySelector(".glider"), {
      slidesToShow: 1,
      slidesToScroll: 2,
      draggable: true,
      dots: "#dots"
    });

    this.observer = new IntersectionObserver(this.handleObserverAS, {
      threshold: 0.1
    });
    this.observer.observe(document.getElementById("anotherServices"));
  },

  destroyed() {
    if (this.observer) this.observer.disconnect();
  },

  computed: {
    ...mapState({
      currentUser: state => state.mobileFSFB.currentUser,
      citas: state => state.mobileFSFB.citas,
      env: state => state.env,
      currentSite: state => state.mobileFSFB.currentSite,
      sedeCode: state => state.mobileFSFB.sedeCode,
      fromQR: state => state.mobileFSFB.fromQR
    }),

    fullname() {
      return this.currentUser
        ? this.currentUser.names +
            " " +
            (this.currentUser.last_name || "") +
            " " +
            (this.currentUser.mother_last_name || "")
        : "";
    }
  },

  methods: {
    ...mapActions({
      goBack: "mobileFSFB/goBack",
      goToView: "mobileFSFB/goToView",
      setCitaCM: "mobileFSFB/setCitaCM",
      sendMessageForPay: "virtualrowFSFB/sendMessageForPay"
    }),

    handleObserverAS(entries) {
      const $arrowBtm = this.$el.querySelector("#arrowBtm");
      entries.forEach(entry => {
        if (this.isArrowBtnFirstTime) {
          if (entry.isIntersecting) {
            $arrowBtm.classList.add("hide");
          } else {
            // $arrowBtm.classList.remove("hide");
          }
        } else {
          this.observer.disconnect();
          $arrowBtm.classList.add("hide");
        }
      });
    },

    onScrollBtm() {
      const $containerData = document.getElementById("ContainerData");
      // 250
      $containerData.scrollTo(0, $containerData.scrollHeight);
      this.isArrowBtnFirstTime = false;
    },

    async onSelectedThisProcedure(cita) {
      if (!this.availableTime(cita)) return;

      this.loading = "loadingSelect";
      let uuid = uuidv4();

      // save data payment
      await this.sendMessageForPay({
        company_id: this.currentSite.branch_id,
        user_document: this.currentUser.doc,
        id_cita_pago: cita.number_form,
        uuid: uuid,
        area: this.$route.query.area,
        docType: this.currentUser.tdoc.code,
        sedeCode: this.sedeCode
      });

      this.selectedSchedule = cita;
      sessionStorage.setItem("appointment", cita.number_form);
      sessionStorage.setItem("fecha_cita", moment(`${cita.date_appoinment} ${cita.hour_appoinment}`).unix());
      sessionStorage.setItem("info_cita", JSON.stringify(cita));
      this.setCitaCM(cita);
    },

    citaHora(hour) {
      //TODO: Por ahora esto no const dateCita = new Date(this.citasEnCM[0].Fecha).getHours();
      //TODO: Esta es forma de solo poner hora const splitFecha = this.citasEnCM[0].Fecha.split(":");
      // const result = `${splitFecha[0].slice(-2)}:${splitFecha[1]}`;
      moment.locale("es");
      return moment(hour).format("hh:mm A");
    },

    citaFecha(date) {
      moment.locale("es");
      return moment(date).format("MMMM DD YYYY");
    },

    availableTime(cita) {
      let minutes = moment().diff(
        moment(`${cita.date_appoinment} ${cita.hour_appoinment}`),
        "minutes"
      );
      minutes = minutes > 0 ? parseInt(minutes) : parseInt(minutes) * -1;
      var isafter =
        moment().unix() -
        moment(`${cita.date_appoinment} ${cita.hour_appoinment}`).unix();
      if (isafter > 0) {
        return minutes <= this.env.VUE_APP_APPOINTMENT_TIME_AVAILABLE_AFTER;
      } else
        return minutes <= this.env.VUE_APP_APPOINTMENT_TIME_AVAILABLE_BEFORE;
    }
  }
};
</script>

<style>
.ContainerHeader {
  background-color: var(--color-radiant-blue);
  margin-bottom: -15px;
}

.fontTitleHeader {
  font-family: var(--font-secondary);
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: var(--color-white);
  padding-top: 18px;
}

.text-Principal,
.text-Small {
  font-weight: 800;
  font-size: 25px;
  line-height: 28px;
  color: #ffffff;
}

.text-Small {
  font-size: 17px;
  line-height: 23px;
}

.bg_dates {
  position: absolute;
  right: 0;
}

.glider-dot.active {
  background: #009dac;
  box-shadow: 0px 0px 0px 5px #009dac3d;
}

.glider-dot {
  width: 8px;
  height: 8px;
  transition: 0.5s all;
}

.subTitle {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  margin: 0 24px;
  font-family: var(--font-secondary);
  color: var(--color-radiant-blue);
}

.ContainerData {
  position: relative;
  border-radius: 20px 20px 0 0;
  background-color: #f2f4f8;
  max-height: calc(100vh - 178px);
  overflow-y: auto;
  scroll-behavior: smooth;
}

.cardCarrousel {
  max-width: 312px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(21, 21, 21, 0.02),
    0px 8px 12px rgba(21, 21, 21, 0.08);
  border-radius: 16px;
}

.doctorName {
  font-size: 16px;
  line-height: 20px;
  color: #788591;
}

.backButton {
  padding-top: 21px;
}

.dateCard {
  background: #daf1fb83;
  border-radius: 13px;
}

.buttonForm {
  height: 48px;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  border: none;
  outline: none;
  border-radius: 16px;
  transition: 0.5s all;
  background: var(--color-radiant);
  color: var(--color-white);
}

.buttonOutlined {
  font-family: var(--font-secondary);
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #009dac;
  border: 2px solid #009dac;
  border-radius: 16px;
  background: none;
}

.disableSchedule {
  opacity: 0.3 !important;
}

.glider {
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
}

.card-glider {
  scroll-snap-align: start;
}

.ArrowDownBold {
  position: fixed;
  right: 50px;
  bottom: 100px;
  border: none;
  outline: none;
  z-index: 100;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: rgba(255, 255, 255, 0);
  box-shadow: 0 10px 15px #1e21241c;
  opacity: 0.9;
  color: #1e2124;
  display: flex;
  align-items: center;
  transition: 0.5s transform;
  justify-content: center;
  transform: translateY(50px);
  animation: 1s ease-in-out alternate-reverse forwards arrowSign;
  animation-iteration-count: 5;
}

.hide {
  visibility: hidden;
}

@keyframes arrowSign {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(10px);
  }
}
</style>
